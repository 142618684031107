// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("D:\\Projects\\Vector\\IntelligentNetworks\\intelligent-networks-site\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("D:\\Projects\\Vector\\IntelligentNetworks\\intelligent-networks-site\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("D:\\Projects\\Vector\\IntelligentNetworks\\intelligent-networks-site\\.cache\\data.json")

